import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  makeStyles,
  Slide,
  TextField,
  Typography,
  ListItem,
  List,
  ListItemIcon,
  Divider,
  InputAdornment,
  IconButton,
  Tooltip,
  CircularProgress,
  Hidden,
  AppBar,
  Toolbar,
  Container,
  Grid,
} from "@material-ui/core";
import { DialogContent, DialogActions } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_CONFIGURATION_NAME,
  SET_PROJECT,
  SET_PROJECTS_LIST,
} from "../../../../store/actions/actions";
import { useMqXS } from "../../../../utils/Utils";
import { UPDATE_SNACKBAR } from "../../../../store/actions/appActions";
import {
  Add,
  AutorenewOutlined,
  CheckCircleOutline,
  CheckOutlined,
  CloseOutlined,
  LinkOffOutlined,
  LinkOutlined,
  SaveOutlined,
  SearchOutlined,
} from "@material-ui/icons";
import LoadingBar from "../../../UI/LoadingBar/LoadingBar";
import { useAxios } from "../../../../utils/useAxios";
import PDFCustomer from "../../../PDF/PDFCustomer";
import PDFInternal from "../../../PDF/PDFInternal";
import { prepareConfiguration } from "../../../../utils/prepareConfiguration";
import PDFOtherCountry from "../../../PDF/PDFOtherCountry";

const useStyles = makeStyles((theme) => ({
  DialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    display: "flex",
    alignItems: "center",
    height: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      height: theme.spacing(7),
    },
  },
  AppBar: {
    borderBottom: `1px solid ${theme.palette.standard.main}`,
  },
  DialogContent: {
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  Container: {
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(2),
    },
  },
  DialogActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  CloseButton: {
    color: theme.palette.primary.contrastText,
  },
  ColorSuccess: {
    color: theme.palette.success.main,
  },
}));

const DialogSaveConfiguration = ({
  whichRole,
  specs,
  accessories,
  modelCode,
  params,
  configuration,
  country,
}) => {
  //const whichRole = "External"; //per testare, poi toglierlo
  const styles = useStyles();
  const mq_xs = useMqXS();
  const dispatch = useDispatch();
  const axios = useAxios();

  const projects_list = useSelector((state) => {
    return state?.configuration?.discount?.projectsList;
  });





  const id_project = useSelector((state) => {
    return state?.configuration?.discount?.id_project;
  });

  const project = useSelector((state) => {
    return state?.configuration?.discount?.project;
  });

  const setProjectsList = (objData) =>
    dispatch({ type: SET_PROJECTS_LIST, value: objData.value });
  const setProject = (objData) =>
    dispatch({ type: SET_PROJECT, id: objData.id, value: objData.value });

  const updateConfigurationName = (objData) =>
    dispatch({ type: SET_CONFIGURATION_NAME, value: objData.value });
  const updateSnackbar = (objData) =>
    dispatch({ type: UPDATE_SNACKBAR, value: objData });

  const [open, setOpen] = useState(false);
  const [name, setName] = useState(configuration?.name || '');
  const [showList, setShowList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [saving, setSaving] = useState(false);

  console.log('ENTROOPP', name);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    updateConfigurationName({ value: "" });
  };

  const handleChangeName = (e) => {

    const newName = e.target.value || ""; // Controllo se il valore è undefined o nullo
    setName(newName); // Aggiorna lo stato locale per il campo di testo
    updateConfigurationName({ value: newName });
  };

  const handleClickLoadList = () => {
    setShowList(true);
    setLoading(true);

    axios
      .get("storage/getproject")
      .then((response) => {
        setProjectsList({ value: response.data });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const newList =
      e.target.value === ""
        ? projects_list
        : projects_list.filter(
          (p) =>
            p.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
        );
    setList(newList);
  };

  const handleSelectProject = (p) => {
    setProject({ id: p.id, value: p.name });
    setShowList(false);
  };

  const handleClickRemoveProject = () => {
    setProject({ id: null, value: null });
    setShowList(false);
  };

  const handleClickCreateProject = () => {
    setProject({ id: null, value: search });
    setShowList(false);
  };

  useEffect(() => {
    if (projects_list?.length > 0) {
      setList(projects_list);
    }
  }, [projects_list]);

  useEffect(() => {
    if (configuration?.archiveInfo?.name) {
      setName(configuration?.archiveInfo?.name)
    }
  }, []);



  const handleClickSave = async () => {
    const configData = prepareConfiguration(
      whichRole,
      configuration,
      params,
      modelCode
    );

    setSaving(true);

    if (configData) {
      await axios
        .put(configData?.url, configData.data)
        .then((response) => {
          if (response.data.result) {
            updateConfigurationName({ value: name });
          }

          updateSnackbar({
            open: true,
            message: response.data.result
              ? "Configuration saved"
              : "Unable to save",
            severity: response.data.result ? "success" : "error",
          });
          handleClose();
        })
        .catch((error) => {
          updateSnackbar({
            open: true,
            message: "Server error",
            severity: "error",
          });
        })
        .finally(() => {
          handleClose();
          setSaving(false);
        });
    }
  };

  const save_icon = <SaveOutlined color="inherit" />;

  return (
    <React.Fragment>
      {(whichRole === "Admin" ||
        whichRole === "Internal" ||
        whichRole === "VIP" ||
        whichRole === "External") && (
          <>
            <PDFCustomer
              specs={specs}
              accessories={accessories}
              model_code={modelCode}
              data={configuration}
              whichRole={whichRole}
              country={country}
            />
          </>
        )}
      {(whichRole === "Admin" ||
        whichRole === "Internal" ||
        whichRole === "VIP") &&
        country === "IT" && (
          <>
            <PDFInternal
              specs={specs}
              accessories={accessories}
              data={configuration}
              model_code={modelCode}
              whichRole={whichRole}
              country={country}
            />
          </>
        )}
      {(whichRole === "Admin" ||
        whichRole === "Internal" ||
        whichRole === "VIP") &&
        country !== "IT" && (
          <>
            <PDFOtherCountry
              specs={specs}
              accessories={accessories}
              data={configuration}
              model_code={modelCode}
              whichRole={whichRole}
              country={country}
            />
          </>
        )}

      <Button
        onClick={handleOpen}
        color="secondary"
        variant="contained"
        size={mq_xs ? "small" : "medium"}
        startIcon={
          params?.id_configuration ? (
            <AutorenewOutlined color="inherit" />
          ) : (
            save_icon
          )
        }
      >
        {params.id_configuration
          ? "Update this configuration"
          : "Save this configuration"}
      </Button>
      {/* {!archive
                ? (id_configuration && !edit_mode)
                    ?
                    <>
                        <Hidden smUp>
                            <CheckCircleOutline classes={{ root: styles.ColorSuccess }} />
                        </Hidden>
                        <Hidden smDown>
                            <Alert severity="success" style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="body2" component="p" color="inherit" style={{ display: 'flex', alignItems: 'center' }}>
                                    Saved into your archive
                                </Typography>
                            </Alert>
                        </Hidden>
                    </>
                    : <Button
                        onClick={handleOpen}
                        color="secondary"
                        variant="contained"
                        size={mq_xs ? 'small' : 'medium'}
                        startIcon={copy_mode
                            ? save_icon
                            : id_configuration || (edit_mode && id_selected)
                                ? <AutorenewOutlined color="inherit" />
                                : save_icon
                        }
                    >
                        {params.id_configuration
                            ? "Update this configuration"

                            : "Save this configuration"
                        }
                    </Button>
                : null
            } */}

      <Dialog
        open={open}
        fullWidth
        fullScreen
        aria-labelledby="Dialog save configuration"
        onClose={handleClose}
      >
        <DialogTitle disableTypography classes={{ root: styles.DialogTitle }}>
          <Typography variant={mq_xs ? "body1" : "h6"} color="inherit">
            {params.id_configuration
              ? "Update configuration"
              : "Save configuration"}
          </Typography>
          <Box ml={"auto"}>
            <IconButton
              classes={{ root: styles.CloseButton }}
              onClick={handleClose}
            >
              <CloseOutlined color="inherit" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers classes={{ root: styles.DialogContent }}>
          <Hidden smDown>
            <AppBar
              color="transparent"
              elevation={0}
              position="relative"
              classes={{ root: styles.AppBar }}
            >
              <Toolbar>
                <Container maxWidth="lg" disableGutters={mq_xs}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                  >
                    <Box mr={mq_xs ? 0 : 1}>
                      <Button
                        color="secondary"
                        size={"medium"}
                        variant="contained"
                        disabled={!name || name === "" || saving}
                        startIcon={
                          !saving ? (
                            params?.id_configuration ? (
                              <AutorenewOutlined color="inherit" />
                            ) : (
                              save_icon
                            )
                          ) : null
                        }
                        onClick={handleClickSave}
                      >
                        {saving ? (
                          <Box display={"flex"} alignItems={"center"}>
                            {params?.id_configuration ? "Updating" : "Saving"}
                            <Box
                              ml={1}
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <CircularProgress
                                style={{ color: "white" }}
                                size={24}
                              />
                            </Box>
                          </Box>
                        ) : params?.id_configuration ? (
                          "Update configuration"
                        ) : (
                          "Save configuration"
                        )}
                      </Button>
                    </Box>
                  </Box>
                </Container>
              </Toolbar>
            </AppBar>
          </Hidden>

          <Container
            maxWidth="lg"
            disableGutters={mq_xs}
            classes={{ root: styles.Container }}
          >
            <Grid container spacing={mq_xs ? 2 : 3}>
              <Grid item xs={12}>
                <TextField
                  required
                  size={mq_xs ? "small" : "medium"}
                  value={name || ''}
                  onChange={handleChangeName}
                  label="Configuration name"
                  name="configuration-name"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Box display={"flex"} alignItems={"center"} mb={3}>
                  <Button
                    variant="text"
                    size="large"
                    color="default"
                    onClick={handleClickLoadList}
                    startIcon={<LinkOutlined />}
                  >
                    <Typography variant="body1" color="primary">
                      {project ? project : "Add project"}
                    </Typography>
                  </Button>
                  {project ? (
                    <Box ml={2}>
                      <Tooltip title="Unlink project">
                        <IconButton
                          color="primary"
                          onClick={handleClickRemoveProject}
                        >
                          <LinkOffOutlined color="inherit" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ) : null}
                </Box>
                <Box>
                  {showList ? (
                    loading ? (
                      <LoadingBar />
                    ) : (
                      <div>
                        <Box mb={3}>
                          <TextField
                            value={search}
                            onChange={handleSearch}
                            name="search"
                            variant="outlined"
                            fullWidth
                            placeholder="Search"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchOutlined color="primary" />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  {search !== "" ? (
                                    <IconButton
                                      color="primary"
                                      onClick={handleClickCreateProject}
                                    >
                                      <Add />
                                    </IconButton>
                                  ) : null}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                        <List>
                          {list.map((p, pIndex) => {
                            return (
                              <div key={pIndex}>
                                <ListItem
                                  button
                                  onClick={() => handleSelectProject(p)}
                                >
                                  {id_project === p.id ? (
                                    <ListItemIcon>
                                      <CheckOutlined color="primary" />
                                    </ListItemIcon>
                                  ) : (
                                    <React.Fragment>
                                      {id_project ? (
                                        <ListItemIcon></ListItemIcon>
                                      ) : null}
                                    </React.Fragment>
                                  )}
                                  <Box py={1} px={2}>
                                    <Typography
                                      variant="body1"
                                      color="primary"
                                    >
                                      {p.name}
                                    </Typography>
                                  </Box>
                                </ListItem>
                                <Divider
                                  orientation="horizontal"
                                  component="li"
                                />
                              </div>
                            );
                          })}
                        </List>
                      </div>
                    )
                  ) : null}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
        <Hidden smUp>
          <DialogActions classes={{ root: styles.DialogActions }}>
            {showList ? (
              <Box px={2} py={1}>
                <Typography variant="body2" align="left" color="primary">
                  {list.length} {list.length === 1 ? "record" : "records"}
                </Typography>
              </Box>
            ) : null}
            {params?.id_configuration ? (
              <CheckCircleOutline classes={{ root: styles.ColorSuccess }} />
            ) : (
              <>
                <Button
                  onClick={handleClickSave}
                  color="secondary"
                  variant="contained"
                  size="small"
                  disabled={!name || name === "" || saving}
                  startIcon={
                    !saving ? (
                      !params.id_configuration ? (
                        save_icon
                      ) : params?.id_configuration ? (
                        <AutorenewOutlined color="inherit" />
                      ) : (
                        save_icon
                      )
                    ) : null
                  }
                >
                  {saving ? (
                    <Box display={"flex"} alignItems={"center"}>
                      {params?.id_configuration ? "Updating" : "Saving"}
                      <Box ml={1} display={"flex"} alignItems={"center"}>
                        <CircularProgress
                          style={{ color: "white" }}
                          size={24}
                        />
                      </Box>
                    </Box>
                  ) : params?.id_configuration ? (
                    "Update configuration"
                  ) : (
                    "Save configuration"
                  )}
                </Button>
              </>
            )}
          </DialogActions>
        </Hidden>
      </Dialog>
    </React.Fragment>
  );
};

export default DialogSaveConfiguration;
