import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
  ADD_MATERIAL_TO_SLOT,
  REMOVE_MATERIAL_FROM_SLOT,
  RESET_COMPARE_SLOTS,
  SET_MATERIAL_MODAL,
} from "../../../../../store/actions/ProductSelector/actions";
import {
  Checkbox,
  Hidden,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { useAxios } from "../../../../../utils/useAxios";
import { useRoleDesc } from "../../../../../utils/useRoleDesc";

const CodeList = (props) => {


  const axios = useAxios();
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );
  const role = useRoleDesc();


  const storeCompareSlots = useSelector((state) => {
    return state.productSelector.compareProducts.slots;
  });

  const dispatch = useDispatch();

  const setMaterialmodal = (objData) =>
    dispatch({
      type: SET_MATERIAL_MODAL,
      code: objData.code,
      class: objData.class,
      description: objData.description,
      specsList: objData.specsList,
    });

  const addMaterialToSlot = (objData) =>
    dispatch({
      type: ADD_MATERIAL_TO_SLOT,
      listType: props.listType,
      value: objData.value,
    });
  const removeMaterialFromSlot = (objData) =>
    dispatch({ type: REMOVE_MATERIAL_FROM_SLOT, value: objData.value });
  const resetCompareSlots = () => dispatch({ type: RESET_COMPARE_SLOTS });

  const handleClickSelectMaterial = (code) => {
    axios.get("productselector/GetProductDetail", {
      params: { material: code },
    })
      .then((response) => {
        resetCompareSlots();
        setMaterialmodal({
          code: response.data.material,
          class: response.data.material_class,
          description: response.data.material_desc,
          specsList: response.data.spec_detail_list,
        });
        props.changeSection(1);
      });
  };

  const toggleMaterialCompare = (e, materialCode) => {
    if (storeCompareSlots.length === 4) {
      if (
        storeCompareSlots.filter((m) => m.code !== materialCode).length === 0
      ) {
        e.preventDefault();
        return;
      }
    }

    if (e.target.checked) {
      addMaterialToSlot({ listType: props.listType, value: materialCode });
    } else {
      removeMaterialFromSlot({ listType: props.listType, value: materialCode });
    }
  };

  const mediaQuery = window.matchMedia("(max-width: 768px)");
  const handleScreenChange = (e) => {
    setIsMobile(e.matches);
  };
  useEffect(() => {
    mediaQuery.addListener(handleScreenChange);

    // Pulizia dell'event listener al momento della disconnessione del componente
    return () => {
      mediaQuery.removeListener(handleScreenChange);
    };
  }, []);

  return (
    <React.Fragment>
      <List>
        {props.list.map((i, index) => {
          return (
            <React.Fragment key={index}>
              <ListItem
                divider
                button
                onClick={() => handleClickSelectMaterial(i.material)}
              >
                <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: '20px', width: '100% ' }}>

                  <div style={{ width: '50%' }}>
                    <ListItemText
                      color="primary"
                      primary={i.material}
                      secondary={
                        i.qty_max === null &&
                          i.lead_time === null
                          ? i.material_desc
                          : i.material_desc +
                          " · Qty. Max:  " +
                          i.qty_max +
                          " · Lead Time:  " +
                          i.lead_time + " days"
                      }
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '10px', alignItems: 'flex-end', width: '80%' }}>
                    {role !== 'External' && <p style={{ margin: '0 0 7px 0', color: '#002b49', width: '40%' }}><span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Gross price :</span>  {i?.gross_price ? i?.gross_price + '€' : 'N/A'} </p>}
                    <p style={{ margin: '0 0 7px 0', color: '#002b49', width: '40%' }}><span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Status :</span> {i?.material_status ? i?.material_status : 'N/A'}</p>
                  </div>


                </div>
                <ListItemSecondaryAction
                  onClick={(e) => toggleMaterialCompare(e, i.material)}
                >
                  <Hidden smDown implementation="css">
                    <Checkbox
                      disabled={
                        storeCompareSlots.length === 4
                          ? storeCompareSlots.filter(
                            (m) => m.code === i.material
                          ).length > 0
                            ? false
                            : true
                          : false
                      }
                      color="primary"
                      inputProps={{ "aria-label": "select for compare" }}
                    />
                  </Hidden>
                </ListItemSecondaryAction>
              </ListItem>
            </React.Fragment>
          );
        })}
      </List>
    </React.Fragment>
  );
};

export default CodeList;
